<template>
  <b-row class="container p-0 m-0 full-container">
    <b-col cols="12" md="12" class="px-1 pb-1 px-md-5 pb-md-5 h-100">
      <div class=" h-100 d-flex flex-column align-items-center justify-content-center">
        <!-- This form info -->
        <div class="mb-2 d-flex flex-column align-items-center justify-content-center">
          <h2 class="mb-1 form-medium-3 mt-3">
            {{ $t('form.onboarding-success') }}
          </h2>
          <!-- image -->
          <img 
            v-if="currentSpace.bgURL || currentSpace.logoURL"
            :src="currentSpace.bgURL || currentSpace.logoURL" 
            class="header-bg" 
          />
          <h3 class="mb-1 form-medium-3 mt-2">
            {{ currentSpaceName }}
          </h3>
        </div>

        <div v-if="spaceRedirect && canRedirect" class="mb-2 d-flex flex-column align-items-center justify-content-center">
          <span class="or-line" style="margin: 0 30px;"></span>
          <!-- Continue to the page where you were going -->
          <div class="mb-2 mt-2 d-flex flex-column align-items-center justify-content-center">
            <p>
              {{ $t('form.onboarding-redirect-detected') }}
            </p>
            <!-- image -->
            <img 
              v-if="spaceRedirect.bgURL || spaceRedirect.logoURL"
              :src="spaceRedirect.bgURL || spaceRedirect.logoURL" 
              class="header-bg2" 
            />
            <h6>
              {{ spaceRedirectName }}
            </h6>
            <p>
              {{ $t('form.onboarding-continue-redirect') }}
            </p>
            <!-- Button to go redirect to space saved in cookies -->
            <button
              class="btn btn-primary mt-2 zindex-1"
              variant="primary"
              @click="goToSpace(spacespaceRedirect)"
            >
              <span>{{ $t('action.continue') }}</span>
            </button> 
            <h6 class="or-line"><span>{{ $t('OR') }}</span></h6>
          </div>
        </div>
        <!-- Go to this space -->
        <div class="mb-2 d-flex flex-column align-items-center justify-content-center">
          <div v-if="canRedirect">
            <p>
              {{ $t('form.onboarding-enter-current') }}
            </p>
            <!--h6>
              {{ currentSpaceName }}
            </h6-->
          </div>
          <!-- Button to enter to currentSpace -->
          <button
            class="btn btn-primary mt-2 zindex-1"
            variant="primary"
            @click="goToSpace(currentSpace)"
          >
            <span>{{ $t('action.enter') }}</span>
          </button> 
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Vue from 'vue';
import { getCollectiveUrl } from "@core/utils/collective";
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'OnboardingSuccess',
  data() {
    return {
      isLoading: false,
      spaceRedirect: {},
      canRedirect: false
    };
  },
  computed: {
    spaceSlug() {
      return this.$route.params.communityId;
    },
    currentSpace() {
      const collective = this.$store.state.collective.collective;
      if(collective?.slug === this.spaceSlug){
        return collective;
      }
      return this.fetchSpaceBySlug(this.spaceSlug);
    },
    subscriptionPlanId() {
      const subscriptionPlanIdByRoute = this.$route.params.subscriptionPlanId;
      console.log('subscriptionPlanIdByRoute', subscriptionPlanIdByRoute);
      return subscriptionPlanIdByRoute !== 'thankyou' ? subscriptionPlanIdByRoute : null;
    },
    subscriptionPlan() {
      return this.$store.getters.item("subscriptionPlans", this.subscriptionPlanId) || null;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    locales() {
      return this.$store.getters.locales;
    },
    availableLanguages() {
      return this.$store.getters.currentCollective.availableLanguages;
    },
    locale() {
      const locale = this.currentLocale;
      if (locale === 'en') {
        return 'en-US';
      }
      if (locale === 'es') {
        return 'es-ES';
      }
      return 'ca-CA';
    },
    currentLocale() {
      if (this.$route.query.code) {
        return this.$route.query.code;
      }
      return this.$store.state.locale.currentLocale;
    },
    spaceSlugToBeRedirectedTo() {
      return Vue.$cookies.get('community') || Vue.$cookies.get('registeredTo') || this.$route.params.communityId;
    },
    currentSpaceName() {
      return this.currentSpace ? this.translate(this.currentSpace.name) : '??';
    },
    spaceRedirectName() {
      return this.spaceRedirect ? this.translate(this.spaceRedirect.name) : '??';
    }
  },
  async created() {
    // console.log('OnboardingSuccess created...');
    // console.log('currentSpace', this.currentSpace);
    this.spaceRedirect = await this.fetchSpaceBySlug(this.spaceSlugToBeRedirectedTo);
    // console.log('this.spaceRedirect', this.spaceRedirect);
    if(this.spaceRedirect?.slug !== this.currentSpace?.slug){
      this.canRedirect = true;
    }
    await this.ResumenUrl();
    // if(this.spaceRedirect.communityMain === this.currentSpace.communityMain){
    //   await this.ResumenUrl();
    // }
  },
  methods: {
    async fetchSpaceBySlug(spaceSlug) {
      return await this.$store.dispatch('getItems', {
        itemType: 'spaces',
        customName: 'spaces',
        requestConfig: {
          slug: spaceSlug,
        }
      })
      .then((response) => {
        if(response.data){
          return response.data[0];
        }
      });
    },
    async ResumenUrl() {
      console.log('this.spaceSlugToBeRedirectedTo:', this.spaceSlugToBeRedirectedTo);

      // TODO: Check if the payment and everything else was correct
      // Súper importante y no está hecho...

      // Join after pay
      const response = await this.$store.dispatch('createItem', {
        item: {
          itemType: 'joinAfterPay',
          requestConfig: {
            communitySlug: this.$route.params.communityId,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
        noSet: true,
      });

      if (response.status === 200 && response.data.status === 3) {
        //TODO: Para qué se cogen las apps???
        // await this.$store.dispatch('getItems', {
        //   itemType: 'apps',
        //   forceAPICall: true,
        // });
        console.log('You were inscribed correctly!!');
      }
    },
    goToSpace(space) {
      const spaceRedirectURL = this.getCollectiveUrlToGoToSpace(space);

      //Delete cookies from onboarding
      this.deleteCookies();

      //Go to url:
      window.location = spaceRedirectURL;
      // window.location = `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace('{slug}', this.spaceSlugToBeRedirectedTo).replace(
      //   '{subdomain}',
      //   'app',
      // )}`;
    },
    deleteCookies() {
      console.log('Deleting onboarding cookies...');
      Vue.$cookies.keys().forEach((cookie) => (cookie === 'redirectTo' ? Vue.$cookies.remove(cookie) : ''));
      Vue.$cookies.keys().forEach((cookie) => (cookie === 'registeredTo' ? Vue.$cookies.remove(cookie) : ''));
      Vue.$cookies.keys().forEach((cookie) => (cookie === 'community' ? Vue.$cookies.remove(cookie) : ''));
      Vue.$cookies.remove('redirectTo');
      Vue.$cookies.remove('registeredTo');
      Vue.$cookies.remove('community');
      return 0;
    },
    getCollectiveUrlToGoToSpace(space) {
      return getCollectiveUrl(space);  //, "/welcome"
    },
    translate(field) {
      for (const row of Object.keys(field)) {
        if (field[row]?.length === 0 || !field[row]) {
          delete field[row];
        }
      }
      if (this.code) {
        return translateTranslationTable(this.code, field);
      }
      if (Object.values(this.availableLanguages).find((language) => language === this.currentLocale)) {
        return translateTranslationTable(this.currentLocale, field);
      }
      return translateTranslationTable(this.availableLanguages[0], field);
    }
  },
};
</script>

<style scoped>
.full-container {
  max-width: 100%;
  width: 100%;
  background-color: white;
  border-radius: 15px;
}
.or-line {
   width: 100%; 
   text-align: center; 
   border-bottom: 1px solid rgb(220, 220, 220); 
   line-height: 0.1em;
   margin: 70px 0 20px; 
} 

.or-line span { 
    background:#fff; 
    padding: 0 10px; 
    color: rgb(154, 154, 154);
}
.header-bg {
  border-radius: 1.2rem;
  /* height: 150px;
  width: 500px;
  object-fit: cover; */
  max-height: 250px;
  margin-top: 2rem;
  background: aliceblue;
}
.header-bg2 {
  border-radius: 1.2rem;
  max-height: 180px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  background: aliceblue;
}
</style>